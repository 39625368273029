import React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import SecondaryHeader from "../../components/SecondaryHeader"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import SupportCenterTopicWrapper, {
  topics,
} from "../../components/SupportCenterLayout"
import Helmet from "react-helmet"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const SupportPage = ({ data }) => {
  return (
    <Layout>
      <GatsbySeo
        title="description"
        description="AlignerBase Support Center: Comprehensive guides for seamless portal use - registration, dashboard navigation, patient management, billing, profile settings."
        />
      <Helmet>
        <meta charset="utf-8" />
      </Helmet>
      <Seo title={`Easy to use cloud platform`} />
      <SecondaryHeader title={<>Support Center</>} />

      <div id="support-center-page">
        <section className="support-center">
          <div style={{ paddingBottom: "50px" }}></div>

          <SupportCenterTopicWrapper>
            <div className="support-center__topic-cards">
              {topics.map((topic, index) => {
                return (
                  <Link to={topic.link}>
                    <div className="card" key={`${topic.label}-card-${index}`}>
                      <Img
                        fluid={data.topicSectionImgs.edges[index].node.fluid}
                      />
                      <div className="support-center__topic-cards__text">
                        <div>{topic.label}</div>
                        <div>{topic.description}</div>
                      </div>
                    </div>
                  </Link>
                )
              })}
            </div>
          </SupportCenterTopicWrapper>
        </section>
      </div>
    </Layout>
  )
}

export default SupportPage

export const query = graphql`
  query {
    topicSectionImgs: allImageSharp(
      sort: { fields: [fluid___originalName], order: ASC }
      filter: {
        fluid: {
          originalName: { regex: "/alignerbase-support-topic-heading-img-*/" }
        }
      }
    ) {
      edges {
        node {
          id
          fluid(maxWidth: 150, maxHeight: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
